import { Component, inject, OnInit } from '@angular/core';
import { ModalService } from '@core/services';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DestroyNotifierDirective } from '@shared/directives/destroy-notifier.directive';

@Component({
  selector: 'hm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  hostDirectives: [
    DestroyNotifierDirective
  ]
})
export class ModalComponent implements OnInit {
  private readonly destroyNotifier = inject(DestroyNotifierDirective)
  private readonly modalService = inject(ModalService)
  private readonly router = inject(Router)

  ngOnInit(): void {
    this.destroyNotifier.sink = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.close();
      })
  }

  close(): void {
    this.modalService.close();
  }
}
